html {
    overflow-x: hidden;
}

.home-hero {
    width: 100%;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    z-index: 0;
}

    .hero-tint {
        background: rgba(0, 0, 0, .5);
        height: 100svh;
        width: 100%;
        z-index: 1;
    }

    .hero-container {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
        margin: 0 auto;
        padding-top: 2em;
        width: 81%;
        z-index: 1;
    }

        .hero-header {
            color: #FFFFFF;
            font-size: 6.5em;
            font-weight: 600;
            line-height: 1em;
            margin-top: .5em;
            text-align: left;
            z-index: 1;
            font-family: 'Poppins', sans-serif;
        }

        .hero-subheading {
            color: #FFFFFF;
            font-size: 1.25em;
            font-weight: 300;
            line-height: 1.8em;
            padding: 1em 0 .75em .5em;
            text-align: left;
            width: 70%;
            z-index: 1;
            font-family: 'Poppins', sans-serif;
        }

        .italic-text {
            font-style: italic;
            font-weight: 600;
            z-index: 1;
        }

        .hero-button__container {
            align-items: flex-start;
            display: flex;
            justify-content: flex-start;
            margin-top: 1em;
            padding-left: .5em;
            width: 100%;
            z-index: 1;
        }

            .contact-hero__contact {
                background: #406FE0;
                border: 1.5px solid #FFFFFF;
                border-radius: 35px;
                color: #FFFFFF;
                font-size: 1.2em;
                margin-right: 1em;
                overflow: hidden;
                padding: 20px 35px;
                position: relative;
                text-align: center;
                text-decoration: none;
                transition: all .35s;
                white-space: nowrap;
                width:fit-content;
                z-index: 1;
            }

                .contact-hero__contact:after {
                    background: #2153CC;
                    content: "";
                    display: block;
                    position: absolute;
                    transition: all .35s;
                    z-index: -1;
                }

                .contact-hero__contact:hover::after {
                    bottom: 0;
                    left: 0;
                    right: 0;
                    top: 0;
                    transition: all .35s;
                }

                .contact-hero__contact::after {
                    bottom: 100%;
                    left: 0;
                    right: 0;
                    top: -100%;
                }

                .contact-hero__offer {
                    background: transparent;
                    border: 1.5px solid #FFFFFF;
                    border-radius: 35px;
                    color: #FFFFFF;
                    font-size: 1.2em;
                    overflow: hidden;
                    padding: 20px 35px;
                    position: relative;
                    text-align: center;
                    text-decoration: none;
                    transition: all .35s;
                    width: fit-content;
                    z-index: 1;
                }

                .contact-hero__offer:after {
                    background: #2153CC;
                    content: "";
                    display: block;
                    position: absolute;
                    transition: all .35s;
                    z-index: -1;
                }

                .contact-hero__offer:hover::after {
                    bottom: 0;
                    left: 0;
                    right: 0;
                    top: 0;
                    transition: all .35s;
                }

                .contact-hero__offer::after {
                    bottom: 100%;
                    left: 0;
                    right: 0;
                    top: -100%;
                }

/* HOME-ONE SECTION */

.home1-section {
    background-color: #2f3c44;
    padding: 3em 0;
    width: 100%;
}

    .home1-bg {
        width: 100%;
    }
        .home1-header {
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background: linear-gradient(to right, #FFFFFF, #406FE0, #2153CC);
            background-clip: text;
            color: #FFFFFF;
            font-size: 3.5em;
            margin: .5em auto;
            padding-bottom: .2em;
            text-align: center;
            width: 90%;
        }

        .home1-header > i {
            font-style: italic;
            font-weight: 500;
        }

        hr {
            background: linear-gradient(to right, #FFFFFF, #009FFD, #2A2A72);
            border: none;
            height: 2px;
            width: 90%;
        }

/* First Home Section Typography and Icons */

    .home1-content {
        align-items: flex-end;
        border-radius: 10px;
        display: flex;
        justify-content: space-evenly;
        margin: 0 auto;
        padding: 2em 0;
        width: 95%;
    }

        .home1-subheading__small {
            display: none;
        }
/* Icon List */

        .home1-icon__wrapper {
            align-items: flex-end;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            width: 45%;
        }

            .home1-icon {
                align-items: center;
                background: linear-gradient(to right, #1E4BB9, #406FE0);
                border: none;
                border-radius: 20px;
                color: #FFFFFF;
                display: flex;
                justify-content: space-between;
                margin-bottom: .7em;
                padding: 1em 0;
                width: 100%;
            }

            .home1-icon__caption {
                font-size: 1.1em;
                margin-left: 10px;
                max-width: 100%;
                text-align: center;
            }

            .last {
                margin-bottom: 0;
            }

/* Subheading and Typography */

    .home1-typography {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 48%;
    }

        .home1-subheading {
            color: #FFFFFF;
            font-size: 2em;
            font-weight: 500;
            line-height: 1.4em;
            margin-bottom: .5em;
            text-align: center;
        }

        .home1-text {
            background-color: #406FE0;
            border-radius: 20px;
            color: #FFFFFF;
            font-size: 1.1em;
            font-weight: 300;
            line-height: 1.6em;
            overflow-x: hidden;
            padding: 1.1em 1em;
            text-align: center;
        }

/* Home1 Section Button */

    .home1-button__container {
        margin: 1em auto 0 auto;
        text-align: center;
        width: 100%;
    }

    .home1-button {
        background: transparent;
        border: 1.5px solid #FFFFFF;
        border-radius: 25px;
        color: #FFFFFF;
        font-family: 'Poppins', sans-serif;
        font-size: 1.2em;
        font-weight: 500;
        margin: .5em 0;
        overflow: hidden;
        padding: 10px 20px;
        position: relative;
        transition: all .35s;
        width: 25%;
        z-index: 1;
    }

/* Home1 Button Hover Animation */

    .home1-button:hover {
        color: #D8EBFB;
        transition: all ease .35s;
    }

    .home1-button:after {
        background: #2153CC;
        content: "";
        display: block;
        position: absolute;
        transition: all .35s;
        z-index: -1;
    }

    .home1-button:hover::after {
        bottom: 0;
        left: 0;
        right: 0;
        top: 0;
        transition: all .35s;
    }

    .home1-button::after {
        bottom: 100%;
        left: 0;
        right: 0;
        top: -100%;
    }

/* Second Home Section */

.home2-section {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
}

    .home2-container {
        background-color: rgba(0, 0, 0, .6);
        padding: 3em 0;
        width: 100%;
    }

/* Home2 Header */

    .home2-header__wrapper {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-bottom: 1em;
        max-width: 100%;
    }

        .home2-header {
            color: #FFFFFF;
            font-size: 3.25em;
            font-weight: 500;
            line-height: 1.2em;
            margin: 0 auto;
            padding-top: .5em;
            text-align: center;
            width: 100%;
        }

        .home2-bold {
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background: linear-gradient(to right, #FFFFFF, #406FE0, #2153CC);
            background-clip: text;
            color: #FFFFFF;
            font-size: 4em;
            font-weight: 600;
            letter-spacing: .3em;
            padding-top: .05em;
            text-align: center;
        }

        .home2-line {
            background: linear-gradient(to right, #FFFFFF, #009FFD, #2A2A72);
            background-color: #FFFFFF;
            border: none;
            height: 2px;
            width: 90%;
        }

/* Home2 Subheading */

        .home2-subheading {
            color: #FFFFFF;
            font-size: 1.2em;
            font-weight: 300;
            line-height: 1.5em;
            margin: 0 auto;
            padding: .5em 0 .1em 0;
            text-align: center;
            width: 80%;
        }

/* Home2 Card Carousel */

    .home2-carousel__container {
        margin: 0 auto;
        max-width: 95%;
        position: relative;
    }

        .home2-carousel__cards {
            padding-bottom: 2em;
            width: 100%;
        }

        .react-multiple-carousel__arrow {
            background: rgba(0, 0, 0, .3);
            margin: 0 -1.2em;
        }

        .react-multiple-carousel__arrow--left::before, .react-multiple-carousel__arrow--right::before {
            color: rgba(255, 255, 255, 0.6);
        }

        .card {
            align-items: center;
            background: rgba( 64, 111, 224, 0.8 );
            border: 2px solid #FFFFFF;
            border: 1.5px solid #FFFFFF;
            border-radius: 5px;
            border-radius: 10px;
            color: #FFFFFF;
            display: flex;
            flex-direction: column;
            height: 90%;
            justify-content: flex-start;
            margin: 30px 5px;
            padding-top: .5em;
            text-align: center;
        }

            .card-icon {
                background: transparent;
                border: 3px solid #FFFFFF;
                border-radius: 100%;
                margin: 1.5em 0 .5em 0;
                padding: 10px 12px;
            }

            .card-heading {
                border-bottom: 2px solid #FFFFFF;
                font-size: 2em;
                font-weight: 500;
                margin: .5em auto;
                padding-bottom: 10px;
                width: 90%;
            }

            .description {
                color: #FFFFFF;
                text-align: center;
                font-size: 1.1em;
                font-weight: 300;
                line-height: 1.8em;
                margin: 0 auto;
                width: 90%;
            }

/* HOME SECTION 3 */

.home3-section {
    background: #2f3C44;
    color: #FFFFFF;
    padding: 4em 0 2em 0;
    width: 100%;
}

/* Home3 Header */

    .home3-header {
        font-size: 3.5em;
        font-weight: 500;
        text-align: center;
        text-shadow:  0 5px 10px #414141;
    }

    .home3-container {
        align-items: center;
        color: #FFFFFF;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-evenly;
        margin: 0 auto;
        padding: 3em 0;
        width: 95%;
    }

/* Home3 Card Components */

        .home3-card__wrapper {
            display: flex;
            flex-direction: row;
            height: 100%;
            justify-content: space-evenly;
            text-align: center;
            width: 100%;
        }

        .home3-card {
            align-items: center;
            background: rgba( 0, 0, 0, 0.9 );
            border-radius: 5px;
            box-shadow: 0 10px 20px rgba(0, 0, 0, 0.4);
            display: flex;
            flex-direction: column;
            height: 85px;
            justify-content: flex-start;
            margin-bottom: .5em;
            padding-top: 1em;
            position: relative;
            text-align: center;
            transition: all 0.3s ease;
            width: 32%;
        }

        .home3-card:last-child {
            margin-bottom: 0;
        }

        .home3-card:hover {
            box-shadow: 0 5px 20px rgba(64, 111, 224, .9);
            height: 100px;
            transition: all .3s ease;
        }

        .home3-card__top {
            align-items: center;
            display: flex;
            justify-content: center;
            padding-top: .2em;
            width: 100%;
        }

            .home3-logo {
                height: 35px;
                width: 35px;
            }

            .home3-card__header {
                font-size: 1.2em;
                font-weight: 500;
                letter-spacing: 1px;
            }

            .home3-card:hover .home3-card__header {
                color: #406FE0;
                transition: all 0.2s ease;
            }

            .home3-card__description {
                font-size: 1em;
                font-weight: 300;
                line-height: 1.3em;
                max-height: 0;
                opacity: 0;
                padding:.1em 0 .5em 0;
                transition: all 0.2s ease;
                width: 90%;
            }

            .home3-card:hover .home3-card__description {
                opacity: 1;
                transition: all 0.2s ease;
            }

/* Home3 Paragraph */

    .home3-info__container {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 1em 0 .5em 0;
        width: 100%;
    }

        .home3-typography {
            font-size: 1.1em;
            font-weight: 300;
            line-height: 1.5em;
            text-align: center;
            width: 95%;
        }

            .phone-number {
                color: #FFFFFF;
                text-decoration: none;
                transition: all .35s;
            }

            .phone-number:hover {
                color: #2153CC;
                transition: all .35s;
            }

/* Home3 Button */

    .home3-link {
        display: block;
        margin-top: 1.5em;
        text-align: center;
        width: 100%;
    }

        .home3-button {
            background: transparent;
            border: 1.5px solid white;
            border-radius: 25px;
            color: #FFFFFF;
            font-family: 'Poppins', sans-serif;
            font-size: 1.2em;
            margin: .5em 0;
            overflow: hidden;
            padding: 10px 30px;
            position: relative;
            transition: all .35s;
            width: 25%;
            z-index: 1;
        }

        .home3-button:hover {
            transition: all ease .35s;
        }

        .home3-button:after {
            background: #2153CC;
            content: "";
            display: block;
            position: absolute;
            transition: all .35s;
            z-index: -1;
        }

        .home3-button:hover::after {
            bottom: 0;
            left: 0;
            right: 0;
            top: 0;
            transition: all .35s;
        }

        .home3-button::after {
            bottom: 100%;
            left: 0;
            right: 0;
            top: -100%;
        }

/* Ending Section */

.end-section {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
}

    .end-container {
        background-color: rgba(0, 0, 0, .6);
        color: #FFFFFF;
        margin: 0 auto;
        padding: 4em 0;
        width: 100%;
    }

/* Heading */

        .end-head__wrapper {
            align-items: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

            .end-logo {
                height: auto;
                width: 25%;
            }

            .end-header {
                font-size: 3em;
                font-weight: 600;
                line-height: 1.1em;
                padding: .5em 0 0 0;
                text-align: center;
                width: 100%;
            }
/* Body */

        .end-info__container {
            margin: 1.5em auto 0 auto;
            padding: .5em;
            width: 70%;
        }

            .end-icons__wrapper {
                align-items: center;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: space-evenly;
                margin: .5em auto;
                width: 90%;
            }

                .icons-wrap__container {
                    align-items: center;
                    display: flex;
                    justify-content: space-around;
                    width: 50%;
                }

                .icons-wrap__container2 {
                    display: none;
                }

            .end-typography {
                font-size: 1.1em;
                font-weight: 300;
                line-height: 1.5em;
                margin: 0 auto .5em auto;
                padding: .5em 0;
                text-align: center;
                width: 98%;
            }

                .end-typography > i {
                    font-style: italic;
                    font-weight: 500;
                }

/* End Contact Button */

        .end-buttons__container {
            align-items: center;
            display: flex;
            justify-content: space-evenly;
            margin: 0 auto;
            padding: 1em 0;
            width: 75%;
        }

            .end-link {
                width: 30%;
            }

            .end-schedule__button {
                background: transparent;
                border: 1.5px solid #FFFFFF;
                border-radius: 25px;
                color: #FFFFFF;
                font-family: 'Poppins', sans-serif;
                font-size: 1.2em;
                overflow: hidden;
                padding: 10px 20px;
                position: relative;
                text-align: center;
                transition: all .35s;
                width: 100%;
                z-index: 1;
            }

            .end-schedule__button:after {
                background: #2153CC;
                content: "";
                display: block;
                position: absolute;
                transition: all .35s;
                z-index: -1;
            }

            .end-schedule__button:hover::after {
                bottom: 0;
                left: 0;
                right: 0;
                top: 0;
            }

            .end-schedule__button::after {
                bottom: 100%;
                left: 0%;
                right: 0;
                top: -100%;
            }

            .end-phone__button {
                background: transparent;
                border: 1.5px solid #FFFFFF;
                border-radius: 25px;
                font-family: 'Poppins', sans-serif;
                overflow: hidden;
                padding: 10px 20px;
                position: relative;
                text-align: center;
                width: 30%;
                z-index: 1;
            }

            .end-phone__button:after {
                background: #2153CC;
                content: "";
                display: block;
                position: absolute;
                transition: all .35s;
                z-index: -1;
            }

            .end-phone__button:hover::after {
                bottom: 0;
                left: 0;
                right: 0;
                top: 0;
            }

            .end-phone__button::after {
                bottom: 100%;
                left: 0%;
                right: 0;
                top: -100%;
            }

            .end-phone__button > a {
                color: white;
                font-size: 1.2em;
                text-decoration: none;
            }

/* MEDIA QUERIES */

@media screen and (max-width: 1156px) {
    .home3-card:hover {
        height: 120px;
    }
}

@media screen and (max-width: 1048px) {

/* hero section */
    .hero-container {
        width: 90%;
    }

    .hero-header {
        font-size: 5.5em;
    }

    .hero-subheading {
        font-size: 1.3em;
    }
        .contact-hero__contact, .contact-hero__offer {
            font-size: 1.2em;
        }

/* Home1 section */
    .home1-content {
        align-items: center;
        flex-direction: column;
    }

        .home1-header {
            line-height: 1.2em;
            margin-bottom: .25em;
        }

        .home1-icon__wrapper {
            padding-bottom: 0;
            width: 75%;
        }

        .home1-subheading__small {
            color: #FFFFFF;
            display: block;
            font-size: 2em;
            font-weight: 500;
            line-height: 1.4em;
            margin-bottom: .25em;
            text-align: center;
            text-shadow: 0 10px 20px rgba(0, 0, 0, 0.4);
        }

        .home1-typography {
            width: 80%;
        }

            .home1-subheading {
                display: none;
            }

            .home1-text {
                background-color: transparent;
                box-shadow: none;
                margin-top: 20px;
                padding: 0;
            }

        .home1-button__container {
            margin-top: 0;
        }

        .home1-button {
            margin: 0;
            width: 70%;
        }

/* Home3 Section */

    .home3-container {
        flex-direction: column;
        padding-bottom: 1em;
    }

        .home3-info__container {
            margin-top: 1em;
            width: 85%;
        }

        .home3-button {
            margin-top: 1em;
            width: 70%;
        }

/* Home-end Section*/

    .end-info__container {
        width: 90%;
    }

    .end-buttons__container {
        width: 90%;
    }

    .end-link {
        width: 40%;
    }

    .end-phone__button {
        width: 40%;
    }
}

/* Media Query #2 */

@media screen and (max-width: 850px) {
    .hero-subheading {
        width: 90%;
    }

    .home1-icon__wrapper {
        width: 95%;
    }

    .home1-typography {
        width: 95%;
    }

    .home3-card__wrapper {
        align-items: center;
        flex-direction: column;
        width: 95%;
    }

        .home3-card {
            width: 100%;
        }

        .home3-card:hover {
            height: 95px;
        }

        .home3-card__header {
            font-size: 1.1em;
        }

    .home3-info__container {
        width: 95%;
    }
}

@media screen and (max-width: 632px) {
    .hero-container {
        padding-top: 4em;
    }

    .hero-subheading {
        font-size: 1.1em;
        width: 95%;
    }

    .contact-hero__contact, .contact-hero__offer {
        border: 1px solid #FFFFFF;
        font-size: 1.1em;
        padding: 8px 15px;
    }

    .home1-header {
        font-size: 3em;
        width: 98%;
    }

    .home2-header {
        font-size: 3em;
    }

    .home1-button {
        border: 1px solid #FFFFFF;
    }

    .home2-bold {
        letter-spacing: normal;
    }

    .home3-button {
        border: 1px solid #FFFFFF;
    }

    .end-container {
        padding-bottom: 2em;
    }

    .end-icons__wrapper {
        flex-direction: column;
    }

        .icons-wrap__container {
            margin: 10px 0;
            width: 100%;
        }

        .end-buttons__container {
            flex-direction: column;
        }

        .end-link {
            width: 100%;
        }

        .end-schedule__button {
            border: 1px solid #FFFFFF;
            font-size: 1.2em;
            width: 100%;
        }

        .end-phone__button {
            border: 1px solid #FFFFFF;
            margin-top: 12px;
            width: 100%;
        }
}

/* media query */

@media screen and (max-width: 550px) {


    .hero-container {
        width: 100%;
    }

        .hero-header {
            font-size: 3.5em;
            margin: 0 auto;
            width: 95%;
            text-align: center;
            letter-spacing: 1.4px;
        }

        .hero-subheading {
            font-size: 1rem;
            text-align: center;
            margin: 0 auto;
            padding: 1em 0 .75em 0;
            width: 95%;
        }

        .hero-button__container {
            align-items: center;
            flex-direction: column;
            justify-content: center;
            margin-top: 0;
            padding-left: 0;
            width: 100%;
        }

            .contact-hero__contact {
                margin-right: 0;
                text-align: center;
                width: 100%;
            }

                .contact-hero__contact, .contact-hero__offer {
                    margin: 5px auto;
                    padding: 15px;
                    width: 80%;
                }

    .home1-header {
        font-size: 2.5em;
        letter-spacing: 2px;
    }

    .home1-subheading__small {
        font-size: 1.8em;
        width: 95%;
    }

    .home1-icon__wrapper {
        margin-top: .5em;
    }

    .home1-icon {
        margin-bottom: .3em;
    }

    .home1-typography {
        width: 100%;
    }

    .home1-text {
        font-size: 1em;
        width: 98%;
    }

    .home1-button {
        font-size: 1.1em;
        padding: 8px 15px;
    }

    .home2-header {
        font-size: 2em;
        line-height: 1.4em;
    }

    .home2-header > br {
        display: none;
    }

    .home2-line {
        width: 95%;
    }

    .home2-subheading {
        font-size: 1em;
        width: 95%;
    }

    .card-heading {
        font-size: 1.5em;
    }

    .description{
        font-size: 1em;
    }

    .home3-section {
        padding: 3em 0 2em 0;
    }

        .home3-header {
            font-size: 3em;
            margin: 0 auto;
            width: 95%;
        }

        .home3-info__container {
            width: 100%;
        }

        .home3-typography {
            font-size: 1em;
        }

        .home3-button {
            font-size: 1.1em;
            padding: 8px 15px;
        }

    .end-icons__wrapper {
        width: 100%;
    }

    .end-typography {
        font-size: 1em;
    }

    .end-schedule__button {
        font-size: 1.1em;
        padding: 8px 15px;
    }

    .end-phone__button {
        padding: 8px 15px;
    }

        .end-phone__button > a {
            font-size: 1.1em;
        }
}

/* mobile media query */

@media screen and (max-width: 454px) {
    .hero-header {
        text-align: center;
    }

    .hero-subheading {
        font-size: 1.1em;
        text-align: center;
    }

    .hero-button__container {
        margin: 0 auto;
    }

    .home1-subheading__small {
        font-size: 1.5em;
    }

    .home1-typography {
        width: 100%;
    }

    .home1-icon {
        padding: .7em 0;
    }

    .home1-icon__caption {
        font-size: 1.1em;
    }

    .home2-bold {
        font-size: 2.5em;
    }

    .home3-header {
        font-size: 2.5em;
    }

    .home3-container {
        padding-top: 1em;
    }

    .home3-card:hover {
        height: 125px;
    }

    .home3-card__header {
        font-size: 1.1em;
    }

    .home3-typography {
        width: 100%;
    }

    .home3-link {
        margin-top: .5em;
    }

    .end-logo {
        height: auto;
        width: 250px;
    }
    .end-header {
        font-size: 2.4em;
        line-height: 1em;
    }

    .icons-wrap__container {
        display: none;
    }

    .icons-wrap__container2 {
        align-items: center;
        display: flex;
        justify-content: space-around;
        margin-bottom: 10px;
        margin-top: 0;
        width: 100%;
    }

    .end-typography {
        margin: 0;
        padding-top: 0;
        width: 100%;
    }
}
