body {
    font-family: 'Poppins', sans-serif;
    margin: 0 auto;
    min-height: 100svh;
}

h1, h2, h3, h4, h5 {
    font-family: 'Poppins', sans-serif;
}

.app {
   user-select: none; 
}