.footer {
    width: 100%;
    background-color: rgba(0, 0, 0, .9);
    padding-bottom: 55px;
    
}

footer {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: flex-start;
    justify-content: flex-start;
    color: #D3D3D3;
    padding: 3em 0 2em 0;
}

/* Left Section */
    .footer-left {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 25%;
        color: rgba(211, 211, 211, .8);
    }

        .footer-left__header {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
        }

        .footer-left__header > img {
            width: 45px;
            height: 45px;
        }

        .footer-company {
            font-size: 1.2em;
            font-weight: 600;
            letter-spacing: 1px;
        }

        .footer-description {
            width: 80%;
            text-align: center;
            font-size: .8em;
            line-height: 1.5em;
        }

    .footer-mid__left {
        width: 25%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: rgba(211, 211, 211, .8);
    }

        .mid-left__header {
            text-align: center;
            font-size: 1.1em;
            margin-bottom: 5px;
            font-weight: 500;
        }

        .mid-left__subheader {
            font-size: .8em;
            text-align: center;
            margin-bottom: 10px;
        }

        .services-list {
            font-size: .7em;
            text-align: center;
            line-height: 1.6em;
        }

    .mid-right {
        width: 25%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        color: rgba(211, 211, 211, .8);
    }

        .mid-right__header {
            font-size: 1.1em;
            font-weight: 500;
            margin-bottom: 10px;
        }

        .general-list {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: center;
            text-decoration: none;
        }
            .general-link {
                text-decoration: none;
                color: rgba(211, 211, 211, .8);
                font-size: .8em;
                margin: 5px 0;
            }

            .general-link:hover {
                color: #2153CC;
            }

    .footer-right {
        width: 25%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: rgba(211, 211, 211, .8);

    }

        .footer-right__header {
            font-size: 1.1em;
            font-weight: 500;
            text-align: center;
        }

        .footer-icons__wrapper {
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            width: 90%;
            margin-top: 10px;
        }

        .footer-icon > a {
            color: #D3D3D3;
            outline: none;
        }

        .footer-icon > a:hover {
            color: #2153CC;
            cursor: pointer;
        }


        .footer-right__description {
            text-align: center;
            font-size: .8em;
            width: 70%;
            margin: 10px 0;
            line-height: 1.5em;
        }

        .footer-right__username {
            display: none;
        }

    .footer-mobile {
        display: none;
    }

    .copy-right {
        letter-spacing: 1px;
        font-size: .8em;
        text-align: center;
        color: rgba(211, 211, 211, .7);
        padding-bottom: .5em;
    }


@media screen and (max-width: 1000px) {

    footer {
        align-items: flex-start;
        justify-content: space-evenly;
    }

    .footer-left {
        display: none;
    }

    .footer-right__description {
        width: 100%;
    }
}

@media screen and (max-width: 820px) {

    .footer-icons__wrapper {
        margin: 10px 0;
    }

    .footer-right__description {
        display: none;
    }

    .footer-right__username {
        display: contents;
        font-size: .8em;
        margin: 10px 0;
    }
}

@media screen and (max-width: 430px) {

    .footer {
        padding-bottom: 45px;
    }
    
    footer {
        padding: 2em 0 1em 0;
    }

    .footer-left, .footer-mid__left, .mid-right, .footer-right {
        display: none;
    }

    .footer-mobile {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: rgba(211, 211, 211, .6);
        font-family: 'Poppins', sans-serif;

    }

        .footer-left__header {
            flex-direction: row;
        }

            .footer-company {
                font-size: 1.3em;
                font-weight: 400;
            }

        .footer-icons__wrapper2 {
            display: flex;
            flex-direction: row;
            width: 70%;
            align-items: center;
            justify-content: space-evenly;
            margin: .5em 0;
        }

        .mobile-subheading {
            font-style: italic;
            font-size: .9em;
            margin: 0 auto;
            line-height: 1.6em;
            text-align: center;
        }
}