.contact-page {
    background-color: #D3D3D3;
    background-position: center center;
    background-repeat: no-repeat;
    color: #FFFFFF;
    font-family: 'Poppins', sans-serif;
    padding-bottom: 6em;
    width: 100%;
}

    .contact-header__container {
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
    }

        .contact-header__tint {
            align-items: center;
            background-color: rgba(0, 0, 0, 0.5);
            display: flex;
            flex-direction: column;
            height: 45svh;
            justify-content: center;
            padding-top: 3em;
            text-align: center;
            width: 100%;
        }

        .contact-header {
            font-size: 4em;
            font-weight: 500;
            margin-bottom: .2em;
            width: 90%;
        }

        .contact-header__typography {
            font-size: 1.3em;
            font-weight: 300;
            line-height: 1.6em;
            width: 85%;
        }

    .contact-container {
        align-items: flex-end;
        display: flex;
        flex-direction: row;
        height: 100%;
        justify-content: space-evenly;
        margin: 0 auto;
        padding: 2em 0;
        width: 100%;
    }

/* header body */
        .contact-info__section {
            align-items: center;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            width: 45%;
        }

            .header-logo {
                height: auto;
                margin-bottom: 2em;
                max-width: 100%;
                gap: 1;
            }
                .contact-lower__wrapper {
                    align-items: center;
                    background-color: #2153CC;
                    border-radius: 10px;
                    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
                    display: flex;
                    flex-direction: column;
                    padding: 2em 10px;
                    width: 100%;
                }

                    .contact-subheading {
                        font-size: 1.2em;
                        font-weight: 300;
                        line-height: 1.5em;
                        text-align: center;
                        width: 90%;
                    }


        /* Form */

        .form-container {
            border-radius: 10px;
            box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
            width: 45%;
        }

        .contact-form {
            border-radius: 10px;
        }

        .form-header {
            color: #FFFFFF;
            font-size: 2em;
            font-weight: 500;
            text-align: center;
            width: 100%;
        }

        .form-subheading {
            color: #FFFFFF;
            font-family: 'Poppins', sans-serif;
            font-size: 1em;
            font-weight: 300;
            line-height: 1.4em;
            margin: .5em 0;
            text-align: center;
        }

/* media query */

@media screen and (max-width: 850px) {
    .contact-page {
        padding-bottom: 3em;
    }

    .contact-header__tint {
        height: 100%;
        padding: 10em 0 3em 0;
    }

    .contact-container {
        align-items: center;
        flex-direction: column;
        justify-content: center;
        padding-top: 1.5em;
    }

        .header-logo {
            max-width: 95%;
            margin: 0 auto;
        }

        .contact-info__section {
            width: 100%;
        }

            .header-logo {
                margin-bottom: 0;
            }

            .contact-lower__wrapper {
                background-color: transparent;
                box-shadow: none;
                color: #414141;
                width: 100%;
            }
            .contact-subheading {
                font-size: 1.1em;
                width: 95%;
            }

    .form-container {
        width: 95%;
    }
}