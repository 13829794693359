.navbar {
    display: flex;
    width: 100%;
    max-width: 100%;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(0, 0, 0, .6);
    padding: 10px 0;
    position: fixed;
    z-index: 999;
    font-family: "Poppins", sans-serif;
}

.navbar .logo-container {
    display: flex;
    align-items: center;
    justify-content: baseline;
    margin-left: 1rem;
    flex-direction: row;
}

.logo-container > a {
    width: 100%;
}

.navbar .logo-container .logo-text {
    color: #FFFFFF;
    font-size: 1.2em;
    padding: 0;
}

.navbar .hamburger {
    color: #FFFFFF;
    display: block;
    margin-right: .9rem;
}

.link-container {
    display: none;
}

.link-container.is-open {
    font-family: 'Poppins', sans-serif;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    left: 0;
    padding: 3em 0;
    position: absolute;
    top: 70px;
    width: 100%;
    gap: 3em;    
}

.fade-in-right {
    animation: fade-in-right 0.5s forwards;
}

    @keyframes fade-in-right {
    from {
    opacity: 0;
    transform: translateX(100%);
    }
    to {
    opacity: 1;
    transform: translateX(0);
    }
}

.link-container li {
    text-decoration: none;
    outline: none;
    font-size: 1.1em;
}

.link-container li .nav-item {
    text-decoration: none;
    color: #FFFFFF;
    font-weight: 400;
    font-size: 1.1em;
    
}

.link-container .contact-nav {
    background-color: transparent;
    border: 1px solid #FFFFFF;
    border-radius: 24px;
    padding: 5px 10px;
    text-decoration: none;
    color: #FFFFFF;
    font-size: 1.1em;
    font-weight: 400;
    font-family: 'Poppins', sans-serif;
}


.navbar.scrolled {
    background-color: #161616;
}

@media screen and (max-width: 375.02px) {
    .navbar .link-container li .item {
        font-size: 1.3em;
    }

    .navbar .link-container .contact-button .contact-item {
        font-size: 1.3em;
    }
}

@media screen and (min-width: 800px) {

    .navbar {
        justify-content: space-between;
    }

    .navbar .hamburger {
        display: none;
    }
    .link-container {
        display: flex;
        justify-content: center;
        align-items: center;
        list-style: none;
        gap: 2em;
    }

    .nav-item{
        background-image: linear-gradient(#2153CC 0 0);
        background-position: 0 100%;
        background-repeat: no-repeat;
        background-size: 0% 2px;
    
         /*OR bottom left*/
        transition:
            background-size 0.3s,
            background-position 0s 0.3s;
    }
    
    .nav-item:hover {
        background-position: 100% 100%;
        background-size: 100% 2px;
         /*OR bottom right*/
    }

    .link-container .contact-button {
        margin-right: 2em;
    }

    .contact-nav {
        z-index: 1;
        position: relative;
        overflow: hidden;
        transition: all .35s;
        background: transparent;
        margin-right: 2em;
    }

    .contact-nav:after {
        content: "";
        background: #2153CC;
        position: absolute;
        z-index: -1;
        display: block;
        transition: all .35s;
    }

    .contact-nav:hover::after {
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
    }

    .contact-nav::after {
        left: -100%;
        right: 100%;
        bottom: 0;
        top: 0;
    }

}